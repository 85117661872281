/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { Grid } from "@material-ui/core";
import React from "react";
import ConnectContainer from "components/connect/ConnectContainer";
import Switcher from "components/athena/ConnectAthenaSwitch";
import {
  AthenaCsvMutationVariables,
  AthenaSqlMutationVariables,
  useAthenaCsvMutation,
  useAthenaSqlMutation,
} from "generated/graphql";
import { authPaths } from "components/router/routes";
import useRouter from "hooks/use-router";

const ConnectAthenaPage: React.FC = () => {
  const sqlMutation = useAthenaSqlMutation();
  const csvMutation = useAthenaCsvMutation();
  const { push } = useRouter();
  const [fileContent, setFileContent] = React.useState<string>("");
  const [page, setPage] = React.useState<string>("GEN_QUERY");
  const handleSubmit = async (payload: AthenaSqlMutationVariables) => {
    await sqlMutation.mutateAsync(payload);
    setPage("GET_QUERY");
  };
  const handleUpload = (payload: AthenaCsvMutationVariables) => {
    csvMutation.mutateAsync(payload);
    push(authPaths.AWS_RESULTS_PROCESSING);
  };

  return (
    <ConnectContainer>
      <Grid
        container
        justify="center"
        sx={{ height: "100%", minHeight: "587px" }}
      >
        <Grid
          item
          container
          xs={12}
          sm={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Switcher
            pageSwitcher={page}
            sql={sqlMutation.data?.AthenaSQL?.sql || ""}
            setPage={setPage}
            handleSubmit={handleSubmit}
            handleFileSelect={setFileContent}
            handleUpload={() => handleUpload({ csv: fileContent })}
            isCsvLoading={csvMutation.isLoading}
            isLoading={sqlMutation.isLoading}
          />
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
export default React.memo(ConnectAthenaPage);
