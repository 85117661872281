/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import diagnosticBg from "assets/diagnostic-bg.jpg";
import diagnosticCtaBg from "assets/diagnostic-cta-bg.png";

import AthenaLineChart from "components/athena/AthenaLineChart";
import AthenaPieChart from "components/athena/AthenaPieChart";
import { ReactComponent as ArrowUpIcon } from "assets/icons/icon-arrow-up.svg";
import { ReactComponent as ArrowRightIcon } from "assets/icons/icon-arrow-right.svg";

import { format } from "date-fns";
import { useAthenaResultsQuery } from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import numbro from "numbro";
import * as React from "react";

interface ChartData {
  cur_cost?: number;
  current?: number;
  optimum?: number;
  min_cost?: number;
  max_savings?: number;
  values?: Array<Array<number>>;
}

const transformData = (data: any) => {
  return (JSON.parse(data) as unknown) as ChartData;
};
const DiagnosticsAthenaPage: React.FC = () => {
  const { user, flow } = useAuth();
  const formattedEndDate = format(new Date(), "MM/dd/yyyy");
  const formattedEndTime = format(new Date(), "hh:mm aaaa");
  const { data, isLoading } = useAthenaResultsQuery(
    {
      flowId: flow?.id || "",
    },
    {
      select: (payload) => transformData(payload.AthenaResults?.results),
    }
  );

  return (
    <Grid container>
      <Grid
        container
        justify="center"
        sx={{
          background: `linear-gradient(180deg, rgba(26, 179, 198, 0.9) 3.49%, #004F9E 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 100%), url(${diagnosticBg})`,
          backgroundSize: "contain",
          height: "100vh",
          color: "white",
        }}
      >
        {isLoading ? (
          <Grid item container alignContent="center" alignItems="center">
            <Grid item xs={12}>
              <CircularProgress size={26} color="primary" />
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            sx={{ mx: 5 }}
            alignContent="center"
            alignItems="center"
            justify="space-between"
          >
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 300,
                }}
              >
                Hi {user?.name}
              </Typography>
              <Typography variant="h2" sx={{ my: 2 }}>
                What we found...
              </Typography>
              <Typography variant="caption">
                Data as of {formattedEndDate} at {formattedEndTime}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="h6"
                sx={{
                  mt: 5,
                  fontWeight: 300,
                  textAlign: "center",
                }}
              >
                Savings (Annualized)
              </Typography>
              <Typography variant="h4" sx={{ my: 1, textAlign: "center" }}>
                {data?.max_savings &&
                  numbro(data.max_savings * 12).formatCurrency({
                    mantissa: 2,
                    thousandSeparated: true,
                  })}
              </Typography>
              <AthenaPieChart
                data={[
                  {
                    name: "Total",
                    value: (data?.cur_cost || 0) * 12,
                  },
                  {
                    name: "Savings",
                    value: (data?.max_savings || 0) * 12,
                  },
                ]}
              />
              <Typography
                variant="h6"
                sx={{
                  mt: 2,

                  fontWeight: 300,
                  textAlign: "center",
                }}
              >
                Spend (Annualized)
              </Typography>
              <Typography variant="h4" sx={{ my: 1, textAlign: "center" }}>
                {data?.cur_cost &&
                  numbro(data.cur_cost * 12).formatCurrency({
                    mantissa: 2,
                    thousandSeparated: true,
                  })}
              </Typography>
            </Grid>
            <Grid container xs={8} alignContent="flex-start">
              <Box display="flex">
                <Typography
                  variant="subtitle1"
                  sx={{
                    writingMode: "vertical-rl",
                    transform: "rotate(180deg)",
                    textOrientation: "mixed",
                    width: "20px",
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  Overall Spend in Account
                </Typography>
                <ArrowUpIcon
                  sx={{
                    height: "93%",
                    transform: "rotate(180deg)",
                  }}
                />
              </Box>

              <Grid item xs={11}>
                <AthenaLineChart chartData={data} />
                <ArrowRightIcon />

                <Typography
                  variant="subtitle1"
                  sx={{ textAlign: "center", fontWeight: 600, mt: -2 }}
                >
                  Hourly Savings Plan Spend
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        justify="center"
        alignContent="center"
        sx={{
          background: `url(${diagnosticCtaBg})`,
          backgroundSize: "cover",
          height: "100vh",
          color: "white",
        }}
      >
        <Grid item container xs={12} sm={6} justify="center" direction="column">
          <Typography
            variant="h2"
            sx={{ color: "#EF7B2E", textAlign: "center" }}
          >
            Want to find out more?
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "#EF7B2E", textAlign: "center", mt: 5 }}
          >
            Connect with a member from our team now
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "#EF7B2E", textAlign: "center" }}
          >
            with our Live Chat or click the button below
          </Typography>
          <Button
            variant="contained"
            sx={{
              mx: "auto",
              px: 5,
              mt: 5,
              backgroundColor: "#EF7B2E",
              color: "white",
            }}
          >
            Please Contact me
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
DiagnosticsAthenaPage.displayName = "DiagnosticsAthenaPage";
export default DiagnosticsAthenaPage;
