/** @jsxImportSource theme-ui */
import { Box, Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { ReactComponent as ClipboardIcon } from "assets/icons/icon-clipboard.svg";
import * as React from "react";
import copyToClipboard from "utils/copy-to-clipboard";

type Props = {
  className?: string;
};

enum TabValues {
  "MACOS_LINUX" = 0,
  "WINDOWS" = 1,
}

const isWindows = () => {
  try {
    return navigator.platform.includes("Win");
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return false;
  }
};

const ScriptCodeBlock: React.FC<Props> = () => {
  const [value, setValue] = React.useState<TabValues>(
    isWindows() ? TabValues.WINDOWS : TabValues.MACOS_LINUX
  );

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };
  let valueToCopy: string;
  switch (value) {
    case TabValues.MACOS_LINUX:
      valueToCopy = `/bin/bash -c "$(curl -fsSL https://raw.githubusercontent.com/virtasant/gcp-onboarding-scripts/master/gcp_once_and_done.sh)"`;
      break;
    case TabValues.WINDOWS:
      valueToCopy = `bitsadmin /transfer myDownloadJob /download /priority normal https://raw.githubusercontent.com/virtasant/gcp-onboarding-scripts/master/gcp_once_and_done.bat "%cd%\\gcp_once_and_done.bat"\ncall gcp_once_and_done.bat`;
      break;
    default:
      throw new Error("impossible state");
  }
  return (
    <>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="script code switcher"
      >
        <Tab label="macOS / Linux" />
        <Tab label="Windows" />
      </Tabs>
      <Box
        component="code"
        sx={{
          backgroundColor: "#F6F7F8",
          px: 2,
          py: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <code
          sx={{
            fontSize: 14,
            maxWidth: "88%",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          {valueToCopy}
        </code>
        <Button
          onClick={() => {
            copyToClipboard(valueToCopy);
          }}
          aria-label="Copy this script"
        >
          <ClipboardIcon width={24} height={24} />
        </Button>
      </Box>
    </>
  );
};
ScriptCodeBlock.displayName = "ScriptCodeBlock";
export default ScriptCodeBlock;
