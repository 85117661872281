/** @jsxImportSource theme-ui */
import { Button, Grid, Typography } from "@material-ui/core";
import ConnectContainer from "components/connect/ConnectContainer";
import GCPTroubleShootingView from "components/connect/GCPTroubleShootingView";
import ScriptCodeBlock from "components/connect/ScriptCodeBlock";
import Modal from "components/general/Modal";
import Hero from "components/onboarding/Hero";
import { authPaths } from "components/router/routes";
import {
  Cloud,
  SetupTypes,
  useSetupScriptMutation,
  useFlowMoveBackMutation,
} from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import useRouter from "hooks/use-router";
import React from "react";

const instructions = [
  "Copy the command above.",
  "Open a terminal session.",
  "From the command line interface, paste the command to use git to pull down the virtasant-setup script.",
  "Execute the script to verify permissions and create the service account and role.",
  "The script will advance you to the next step.",
];

const ConnectGcpPage: React.FC = () => {
  const { location } = useRouter();
  const dataFromHash = location.hash;
  const { profileQuery, flow } = useAuth();
  const setupScript = useSetupScriptMutation();
  const backMutation = useFlowMoveBackMutation();
  const handleBack = async () => {
    await backMutation.mutateAsync({
      delete: true,
      flowId: flow?.id || "",
    });
    localStorage.removeItem("cloud-flow");
    profileQuery?.refetch();
  };
  React.useEffect(() => {
    const updateCompanyStage = async () => {
      if (setupScript?.isSuccess) return;
      try {
        await setupScript?.mutateAsync({
          cloud: Cloud.Gcp,
          flowId: flow?.id || "",
        });
        await profileQuery?.refetch();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    let jsonEncodedValue;
    if (dataFromHash) {
      jsonEncodedValue = dataFromHash.replace(/^#/, "");
      localStorage.setItem("hash-json", jsonEncodedValue);
      window.location.replace(`${authPaths.CONNECT}/${SetupTypes.CloudGcp}`);
    }
    if (!jsonEncodedValue) {
      jsonEncodedValue = localStorage.getItem("hash-json");
    }
    if (jsonEncodedValue) {
      updateCompanyStage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConnectContainer>
      <Grid
        container
        justify="center"
        sx={{ height: "100%", minHeight: "587px" }}
      >
        <Grid
          item
          container
          xs={12}
          lg={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            item
            sm={4}
            md={6}
            lg={6}
            container
            justify="center"
            alignItems="flex-start"
          >
            <Grid item xs={12} md={10} sx={{ my: "auto" }}>
              <Hero title="Service account setup">
                <Typography variant="h5" sx={{ mt: 3, color: "#636C74" }}>
                  The Diagnostic tool will require the setup of a new service
                  account. This service account will be used to pull
                  configuration, utilization, and cost data for the analysis.
                </Typography>
                <Typography variant="h5" sx={{ mt: 3, color: "#636C74" }}>
                  If you have any issues, please consulting our Troubleshooting
                  Guide.
                </Typography>
              </Hero>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={6} sx={{ my: 5, px: 4 }}>
            <ScriptCodeBlock />
            <ol sx={{ fontSize: "24px" }}>
              {instructions.map((instruction) => (
                <li key={instruction}>
                  <Typography variant="subtitle1">{instruction}</Typography>
                </li>
              ))}
            </ol>
          </Grid>
          <Grid
            container
            sx={{ mb: 4, mx: 4 }}
            justify="space-between"
            alignContent="flex-end"
          >
            <Grid item xs={2}>
              <Button
                onClick={handleBack}
                variant="text"
                color="primary"
                fullWidth
              >
                Back
              </Button>
            </Grid>
            <Modal
              title="GCP Troubleshooting"
              renderButton={({ onClick }) => {
                return (
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={onClick}
                    >
                      Troubleshooting
                    </Button>
                  </Grid>
                );
              }}
              renderChildren={GCPTroubleShootingView}
            />
          </Grid>
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
ConnectGcpPage.displayName = "Connect Gcp";
export default ConnectGcpPage;
