export const authPaths = {
  SIGNIN: "/",
  SIGNUP: "/signup",
  VERIFY_CODE: "/verify",
  CHOOSE_PLATFORM: "/choose-platform",
  CONNECT: "/connect",
  VERIFY_SETUP: "/verify-setup",
  CHOOSE_REGIONS: "/choose-regions",
  AWS_RESULTS_PROCESSING: "/aws-setup-results",
  ATHENA_RESULTS_PROCESSING: "/athena-setup-results",
  RESULTS_READY: "/results-ready",
  DIAGNOSTIC_DASHBOARD: "/diagnostics",
} as const;

export const generalPaths = {
  TOS: "/tos",
};

export const ADMIN_ROOT = "/admin";

export const adminPaths = {
  COMPANY_DETAILS: `${ADMIN_ROOT}/company`,
  BUSINESS_UNITS: `${ADMIN_ROOT}/business-units`,
  USERS: `${ADMIN_ROOT}/users`,
};
