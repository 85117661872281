/* eslint-disable global-require */
/** @jsxImportSource theme-ui */
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Image from "material-ui-image";
import React from "react";
import { SetupTypes } from "../../generated/graphql";

interface Platform {
  img: string;
  value: SetupTypes;
  name: string;
}
interface Props {
  selected: SetupTypes | null;
  setSelected: (value: SetupTypes) => void;
  items: Platform[];
}

const PlatformRadioGroup: React.FC<Props> = ({
  items,
  selected,
  setSelected,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value as Platform["value"]);
  };
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      sx={{ flex: 1, mt: 5 }}
    >
      <>
        {items.map((platform) => (
          <Grid item key={platform.value} xs={6} sx={{ textAlign: "center" }}>
            <Box width="120px" sx={{ textAlign: "start", mx: "auto" }}>
              <Image src={platform.img} aspectRatio={16 / 9} />
            </Box>
            <RadioGroup
              row
              aria-label="platform-radio"
              name="platform-radio"
              defaultValue="aws"
              onChange={handleChange}
              value={selected}
              sx={{ mt: 4 }}
            >
              <FormControlLabel
                value={platform.value}
                control={<Radio />}
                label={<Typography variant="body1">{platform.name}</Typography>}
                sx={{ mx: "auto" }}
              />
            </RadioGroup>
          </Grid>
        ))}
      </>
    </Grid>
  );
};
PlatformRadioGroup.displayName = "PlatformRadioGroup";
export default PlatformRadioGroup;
