/** @jsxImportSource theme-ui */
import * as React from "react";
import { Box } from "@material-ui/core";
import OnboardingBar from "../navigation/OnboardingBar";

const ConnectContainer: React.FC = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundImage: `linear-gradient(288.06deg, #F4A36D 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png)`,
        height: "100%",
        minHeight: "100vh",
        px: 20,
      }}
    >
      <OnboardingBar
        sx={{
          mt: 88,
        }}
      />
      {children}
    </Box>
  );
};

export default ConnectContainer;
