/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useAthenaSetupArnMutation } from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import * as React from "react";
import { useForm } from "react-hook-form";
import WhereToFindARN from "./WhereToFindARN";

const VerifyAWSSetupForm: React.FC = () => {
  const { profileQuery, flow } = useAuth();
  const [isARNModalOpen, setARNModalOpen] = React.useState(false);
  const handleOpenARNModal = () => {
    setARNModalOpen(true);
  };
  const handleCloseARNModal = () => {
    setARNModalOpen(false);
  };
  const setupRole = useAthenaSetupArnMutation({
    onSuccess: () => {
      profileQuery?.refetch();
    },
  });
  const { register, handleSubmit, formState } = useForm<{
    roleARN: string;
  }>({
    mode: `onChange`,
    defaultValues: {
      roleARN: "",
    },
  });

  const onSubmitHandler = handleSubmit(async ({ roleARN }) => {
    try {
      await setupRole?.mutate({
        arn: roleARN,
        flowId: flow?.id || "",
      });
    } catch (e) {
      // TODO: Handle error
    }
  });
  const isLoading = setupRole.isLoading || setupRole.isSuccess;
  const disabled = !formState.isValid || isLoading;

  return (
    <>
      <WhereToFindARN open={isARNModalOpen} onClose={handleCloseARNModal} />
      <Box
        component="form"
        onSubmit={onSubmitHandler}
        sx={{
          height: "100%",
          position: "relative",
          px: [30, 0],
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box sx={{ maxWidth: [, 490] }}>
          <Typography variant="subtitle1">
            Please copy and paste your ARN Role code.
          </Typography>
          <TextField
            inputRef={register({
              required: `You must provide a Role ARN`,
            })}
            inputProps={{ required: true }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="verify-code-input"
            label="Enter Role ARN"
            name="roleARN"
            type="text"
            autoFocus
            sx={{
              mt: 4,
            }}
          />
          <Box
            sx={{
              textAlign: "right",
            }}
          >
            <Button
              type="button"
              color="primary"
              sx={{
                px: 4,
                marginRight: -32,
              }}
              onClick={handleOpenARNModal}
            >
              Where do I find my ARN code?
            </Button>
          </Box>
        </Box>
        <Box sx={{ position: "relative" }}>
          {setupRole?.isError && (
            <Box sx={{ position: "absolute" }}>
              {setupRole?.error?.graphqlErrors?.map((error, index) => {
                return (
                  <Alert severity="error" key={`${error.message}-${index}`}>
                    {error.message}
                  </Alert>
                );
              })}
            </Box>
          )}
        </Box>
        <Button
          disabled={disabled}
          type="submit"
          aria-label="submit"
          variant="contained"
          color="primary"
          sx={{
            px: 4,
            mt: [4, 0],
            position: [, "absolute"],
            right: [, 20],
            bottom: [, 20],
            minWidth: ["100%", 250],
          }}
        >
          {isLoading ? (
            <CircularProgress size={26} color="primary" />
          ) : (
            "Connect to AWS"
          )}
        </Button>
      </Box>
    </>
  );
};
VerifyAWSSetupForm.displayName = "VerifyAWSSetupForm";
export default VerifyAWSSetupForm;
