/** @jsxImportSource theme-ui */
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import useRequireAuth from "hooks/use-require-auth";
import Hero from "components/onboarding/Hero";

import ConnectContainer from "components/connect/ConnectContainer";
import { useAuth } from "hooks/use-auth";

import { useFlowSetupMutation, SetupTypes } from "../generated/graphql";

const ConnectAthenaPage: React.FC = () => {
  const { setCurrentFlow, profileQuery } = useAuth();
  useRequireAuth();
  const { mutateAsync, isLoading } = useFlowSetupMutation();
  const [platform, setPlatform] = React.useState<SetupTypes>(
    SetupTypes.SavingsPlanQuery
  );
  const handlePlatformChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPlatform((event.target.value as unknown) as SetupTypes);
  };
  const handleClick = async () => {
    if (platform !== null) {
      const data = await mutateAsync({ setupType: platform });
      if (setCurrentFlow !== null) {
        setCurrentFlow(data?.FlowSetup?.flow?.id || "");
        profileQuery?.refetch();
      }
    }
  };
  const isNextButtonDisabled = !platform;

  return (
    <ConnectContainer>
      <Grid
        container
        sx={{
          bg: "white",
          borderRadius: 10,
          minHeight: [, 515],
          maxWidth: ["100%", 1240],
          mx: "auto",
          pt: [20, 0],
        }}
      >
        <Grid item sm={4} md={6} container justify="center" alignItems="center">
          <Grid item md={8}>
            <Hero title="Cost & Usage Access Model" />
          </Grid>
        </Grid>
        <Grid
          item
          sm={8}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pl: 3,
            pr: 3,
          }}
        >
          <Grid container sx={{ mt: "auto" }}>
            <Grid item>
              <Typography component="h1" variant="h5">
                Please select your preffered approach with respect to sharing
                your CUR data
              </Typography>
            </Grid>
            <Grid item sx={{ width: "100%", mr: 4, mt: 4 }}>
              <Select
                sx={{ width: "100%" }}
                variant="outlined"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={platform}
                onChange={handlePlatformChange}
                label="Age"
              >
                <MenuItem value={SetupTypes.SavingsPlanQuery}>
                  Manual Database Query
                </MenuItem>
                <MenuItem value={SetupTypes.SavingsPlanAthena}>
                  Cross Account Access
                </MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" sx={{ pr: 4, pb: 4, mt: "auto" }}>
            <Grid item xs={3}>
              <Button
                disabled={isNextButtonDisabled}
                size="medium"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleClick}
              >
                {isLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Next"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
ConnectAthenaPage.displayName = "ConnectAthenaPage";
export default ConnectAthenaPage;
