import { Button, Grid } from "@material-ui/core";
import React, { useRef } from "react";

interface Props {
  onFileSelect: (f: File) => void;
}
const FileUploader: React.FC<Props> = ({ onFileSelect }) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const handleFileInput = (e: any) => {
    // handle validations
    onFileSelect(e.target.files[0]);
  };
  return (
    <Grid item container justify="flex-end">
      <input type="file" onChange={handleFileInput} hidden ref={fileInput} />
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={(e) => fileInput.current && fileInput.current?.click()}
      >
        Select File
      </Button>
    </Grid>
  );
};
export default FileUploader;
