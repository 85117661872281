/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Hero from "components/onboarding/Hero";
import useRouter from "hooks/use-router";
import SelectTemplate from "components/connect/SelectAthenaTemplate";
import { useTemplateQuery } from "api/athena.service";
import {
  Cloud,
  useRegionsQuery,
  useAthenaSetupParametersMutation,
} from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import { Link as RouterLink } from "react-router-dom";
import { generateAthenaUrl } from "components/connect/connect-utils";
import { authPaths } from "components/router/routes";
import ConnectContainer from "components/connect/ConnectContainer";

const ConnectAthenaCFPage: React.FC = () => {
  const { user, profileQuery, flow } = useAuth();
  const setupParameters = useAthenaSetupParametersMutation();
  const { push } = useRouter();
  const [region, setRegion] = useState<string>("");
  const [dbName, setDbName] = useState<string>("");
  const [tableName, setTableName] = useState<string>("");
  const [workgroup, setWorkgroup] = useState<string>("");
  const [curData, setCurData] = useState<boolean>(false);

  const templateQuery = useTemplateQuery();
  const regionsQuery = useRegionsQuery();
  const isLoading = setupParameters.isLoading || setupParameters.isSuccess;
  const disabled =
    isLoading ||
    !templateQuery.isSuccess ||
    !region ||
    ![region, dbName, tableName, workgroup].every((el) => el !== "");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRegion(event.target.value as string);
  };

  const handleSubmit = async () => {
    const companyUuid = user?.company?.uuid;
    if (!companyUuid) {
      throw new Error(`CompanyUuid should be defined`);
    }
    if (!region) {
      throw new Error(`region should be set`);
    }
    await setupParameters?.mutateAsync({
      dbName,
      tableName,
      workgroup,
      region,
      flowId: flow?.id || "",
    });
    await profileQuery?.refetch();
    const url = generateAthenaUrl(
      region,
      companyUuid,
      dbName,
      tableName,
      workgroup,
      user?.email
    );
    console.log(url);
    push(`${authPaths.VERIFY_SETUP}/${Cloud.Aws}`);
    window.open(url, "_blank");
  };
  const availableRegions = (regionsQuery.data?.aws?.regions as string[]) || [];

  return (
    <ConnectContainer>
      <Grid
        container
        justify="center"
        sx={{ height: "100%", minHeight: "587px" }}
      >
        <Grid
          item
          container
          xs={12}
          sm={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            item
            sm={4}
            md={6}
            lg={6}
            container
            justify="center"
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
            <Grid item xs={12} md={10} sx={{ my: "auto" }}>
              <Hero title="Provide Athena database, table and wg info" />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={6} sx={{ my: 5, px: 4 }}>
            <Box sx={{ pt: 4 }}>
              <TextField
                required
                id="athena-db-name"
                label="Athena Database Name"
                variant="outlined"
                sx={{ width: "100%", mt: 1 }}
                onChange={(e) => setDbName(e.target.value)}
              />
              <TextField
                required
                id="athena-db-table-name"
                label="Athena Database Table Name"
                variant="outlined"
                sx={{ width: "100%", mt: 2 }}
                onChange={(e) => setTableName(e.target.value)}
              />
              <TextField
                required
                id="athena-db-working-group"
                label="Athena Working Group"
                variant="outlined"
                sx={{ width: "100%", mt: 2 }}
                onChange={(e) => setWorkgroup(e.target.value)}
              />
              <SelectTemplate
                availableRegions={availableRegions}
                setupScript={setupParameters}
                templateQuery={templateQuery}
                region={region}
                onChange={handleChange}
              />
              {/* <FormControlLabel
                sx={{ mt: 0 }}
                control={
                  <Checkbox
                    checked={curData}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setCurData(event.target.checked)
                    }
                    name="EnableCurData"
                  />
                }
                label="Enable CUR Data"
              /> */}
            </Box>
          </Grid>
          <Grid
            container
            sx={{ mb: 4, mx: 4 }}
            justify="space-between"
            alignContent="flex-end"
          >
            <Grid item xs={2}>
              <Button
                variant="text"
                color="primary"
                fullWidth
                to={authPaths.CONNECT}
                component={RouterLink}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={disabled}
                variant="contained"
                size="medium"
                color="primary"
                disableElevation
                fullWidth
                onClick={handleSubmit}
                sx={{
                  mt: 2,
                  width: "100%",
                }}
              >
                {isLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Run Script"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
ConnectAthenaCFPage.displayName = "ConnectAthenaCFPage";
export default ConnectAthenaCFPage;
