/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import OnboardingBar from "components/navigation/OnboardingBar";
import Hero from "components/onboarding/Hero";
import { authPaths } from "components/router/routes";
import VerifyGCPModal from "components/verify-setup/VerifyGCPModal";
import {
  useFlowMoveBackMutation,
  useSetupRoleMutation,
} from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import useRouter from "hooks/use-router";
import * as React from "react";

const parseJson = (unparsed: string | string[] | null) => {
  try {
    if (typeof unparsed !== "string") {
      throw new Error("json param should be a string");
    }
    const parsedJson = atob(unparsed);
    return parsedJson;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return "ERROR";
  }
};

const VerifyGCP: React.FC = () => {
  const { push } = useRouter();
  const cliResultsJson = React.useRef(
    parseJson(localStorage.getItem("hash-json"))
  );
  const cliResultsJsonData = cliResultsJson.current;

  const { profileQuery, flow } = useAuth();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [projectName, setProjectName] = React.useState<string>("");
  const [dataSet, setDataSet] = React.useState<string>("");
  const backMutation = useFlowMoveBackMutation();
  React.useEffect(() => {
    if (cliResultsJsonData === "ERROR") {
      setIsModalOpen(true);
    }
  }, [cliResultsJsonData]);
  const handleBack = async () => {
    localStorage.removeItem("hash-json");
    await backMutation.mutateAsync({
      flowId: flow?.id || "",
    });
    profileQuery?.refetch();
  };
  const setupRole = useSetupRoleMutation();
  const isLoading = setupRole.isLoading || setupRole.isSuccess;
  const disabled = isLoading || !cliResultsJsonData;
  const handleSubmit = async () => {
    try {
      await setupRole?.mutateAsync({
        credentials: {
          file: cliResultsJsonData,
          projectName,
          dataSet,
        },
        flowId: flow?.id || "",
      });
      profileQuery?.refetch();
      push(authPaths.CHOOSE_REGIONS);
      localStorage.removeItem("hash-json");
    } catch (e) {
      setIsModalOpen(true);
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundImage: `linear-gradient(288.06deg, #F4A36D 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png);`,
        backgroundRepeat: "repeat",
        backgroundSize: "cover",
        width: "100%",
      }}
    >
      <OnboardingBar
        sx={{
          mt: 88,
          px: [30, 0],
        }}
      />
      <Grid container justify="center">
        <Grid
          item
          container
          xs={11}
          lg={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            item
            sm={6}
            container
            justify="center"
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
            <Grid item xs={12} md={10} sx={{ mt: 4 }}>
              <Hero title="Connecting CO to your GCP">
                <Typography
                  sx={{
                    mt: 4,
                    // fontWeight: 600,
                    color: "##636C74",
                    fontSize: 24,
                  }}
                >
                  Now that the setup of the Service Account is complete, please
                  review the Service Account Key information. Once you submit,
                  we will begin the analysis.
                </Typography>
              </Hero>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              my: 5,
              px: 4,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                mt: 2,
                mb: 2,
                color: "#F26D2F",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              Cost Data (Optional)
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Please provide the BigQuery information for cost data.
            </Typography>
            <TextField
              size="small"
              fullWidth
              id="project-name"
              label="Cost Project Name"
              variant="outlined"
              sx={{ mb: 3 }}
              onChange={(val) => setProjectName(val.target.value)}
            />
            <TextField
              size="small"
              fullWidth
              id="dataset-name"
              label="Cost DataSet Name"
              variant="outlined"
              sx={{ mb: 3 }}
              onChange={(val) => setDataSet(val.target.value)}
            />

            <Typography
              variant="h5"
              sx={{
                mt: 2,
                mb: 2,
                color: "#F26D2F",
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              Review Google Service Account Key
            </Typography>

            <Typography variant="body1" sx={{ mb: 2 }}>
              Contents of JSON file below. Please confirm, then Submit.
            </Typography>
            <Box
              sx={{
                bg: "#F6F7F8",
                overflow: "scroll",
                width: "100%",
                height: 150,
              }}
            >
              <code>{cliResultsJsonData}</code>
            </Box>
          </Grid>
          <Grid
            container
            sx={{ mb: 4, mx: 4 }}
            justify="space-between"
            alignContent="flex-end"
          >
            <Grid item xs={2}>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={handleBack}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={disabled}
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <VerifyGCPModal
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </Box>
  );
};
VerifyGCP.displayName = "VerifyGCP";
export default VerifyGCP;
