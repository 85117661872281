/** @jsxImportSource theme-ui */
import * as React from "react";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

interface ChartProps {
  chartData?: any;
}

const AthenaLineChart: React.FC<ChartProps> = ({ chartData }) => {
  let values;
  if (chartData) {
    values = chartData.values?.map((value: Array<number>) => ({
      x: value[0],
      y: value[1],
    }));
  }
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={values}
        margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
      >
        <XAxis
          dataKey="x"
          domain={["auto", "auto"]}
          tick={false}
          type="number"
          stroke="transparent"
        />
        <ReferenceLine
          segment={[
            { x: chartData.current, y: chartData.min_cost },
            { x: chartData.current, y: chartData.cur_cost },
          ]}
          stroke="white"
          alwaysShow
          label={{
            position: "bottom",
            offset: -5,
            value: "Current",
            fill: "red",
            fontSize: 14,
          }}
        />
        <ReferenceLine
          segment={[
            { x: chartData.optimum, y: chartData.min_cost },
            { x: chartData.optimum, y: chartData.cur_cost },
          ]}
          stroke="#abdeb8"
          alwaysShow
          label={{
            position: "top",
            value: "Suggested",
            fill: "#07e319",
            fontSize: 14,
          }}
        />

        <YAxis
          domain={["auto", "auto"]}
          tick={false}
          type="number"
          stroke="transparent"
        />

        <Line dataKey="y" dot stroke="white" width={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};
AthenaLineChart.displayName = "AthenaLineChart";
export default React.memo(AthenaLineChart);
