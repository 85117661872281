// Hook (use-require-auth.js)
import { useEffect } from "react";
import env from "utils/env";
import { getNextOnboardingAthenaPath } from "utils/get-next-onboarding-athena-path";
import { useAuth } from "./use-auth";
import useRouter from "./use-router";

const useRequireAthenaAuth = () => {
  const auth = useAuth();
  const { push, location, query } = useRouter();
  const next = Array.isArray(query.next) ? query.next[0] : query.next;

  useEffect(() => {
    const nextPath = getNextOnboardingAthenaPath(
      location.pathname,
      auth.user,
      auth.flows,
      auth.flow,
      next
    );
    if (env("NODE_ENV") === "development") {
      // eslint-disable-next-line no-console
      console.log(`redirect to ${nextPath}`, {
        path: location.pathname,
        user: auth.user,
      });
    }
    if (nextPath) {
      push(nextPath);
    }
  }, [auth.flow, auth.flows, auth.user, location.pathname, next, push]);

  return { ...auth };
};
export default useRequireAthenaAuth;
