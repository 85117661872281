/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import {
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import Hero from "components/onboarding/Hero";
import { ToSDialog } from "components/general/ToS";
import useRouter from "hooks/use-router";
import SelectTemplate from "components/connect/SelectTemplate";
import { useTemplateQuery } from "api/aws.service";
import {
  Cloud,
  useRegionsQuery,
  useSetupScriptMutation,
  useFlowMoveBackMutation,
} from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import { generateUrl } from "components/connect/connect-utils";
import { authPaths } from "components/router/routes";
import ConnectContainer from "components/connect/ConnectContainer";

const ConnectAwsPage: React.FC = () => {
  const { user, profileQuery, flow } = useAuth();
  const setupScript = useSetupScriptMutation();
  const { push } = useRouter();
  const [region, setRegion] = useState<string>("");
  const templateQuery = useTemplateQuery();
  const regionsQuery = useRegionsQuery();
  const backMutation = useFlowMoveBackMutation();
  const handleBack = async () => {
    await backMutation.mutateAsync({
      delete: true,
      flowId: flow?.id || "",
    });
    localStorage.removeItem("cloud-flow");

    profileQuery?.refetch();
  };
  const isLoading = setupScript.isLoading || setupScript.isSuccess;
  const disabled = isLoading || !templateQuery.isSuccess || !region;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRegion(event.target.value as string);
  };

  const handleSubmit = async () => {
    const companyUuid = user?.company?.uuid;
    if (!companyUuid) {
      throw new Error(`CompanyUuid should be defined`);
    }
    if (!region) {
      throw new Error(`region should be set`);
    }
    await setupScript?.mutateAsync({
      region,
      cloud: Cloud.Aws,
      flowId: flow?.id || "",
    });
    await profileQuery?.refetch();
    const url = generateUrl(companyUuid, region);
    push(`${authPaths.VERIFY_SETUP}/${Cloud.Aws}`);
    window.open(url, "_blank");
  };
  const availableRegions = (regionsQuery.data?.aws?.regions as string[]) || [];

  const HeroSubtitle: React.FC = () => {
    const [open, setOpen] = React.useState<boolean>(false);
    return (
      <>
        <Typography sx={{ mt: 4 }}>
          Please review
          <Link
            component="button"
            variant="body1"
            onClick={(event: React.MouseEvent) => {
              event.preventDefault();
              setOpen(true);
            }}
            sx={{ ml: 2 }}
          >
            Terms of Service.
          </Link>
        </Typography>
        <ToSDialog open={open} onClose={() => setOpen(false)} />
      </>
    );
  };
  return (
    <ConnectContainer>
      <Grid
        container
        justify="center"
        sx={{ height: "100%", minHeight: "587px" }}
      >
        <Grid
          item
          container
          xs={12}
          sm={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            item
            sm={4}
            md={6}
            lg={6}
            container
            justify="center"
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
            <Grid item xs={12} md={10} sx={{ my: "auto" }}>
              <Hero title="Connect your AWS account">
                <HeroSubtitle />
              </Hero>
              <Typography variant="h6" sx={{ mt: 4, fontWeight: 400 }}>
                Note: Before clicking on the “Run Script” button, please make
                sure in a separate browser tab to log into your AWS account.
                Then click “Run Script” to create the cross account role.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} md={6} sx={{ my: 5, px: 4 }}>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
              }}
            >
              Please create a cross account role to provide access to diagnostic
              tool. Select the primary region for your AWS account from the
              drop-down below.
            </Typography>
            <SelectTemplate
              availableRegions={availableRegions}
              setupScript={setupScript}
              templateQuery={templateQuery}
              region={region}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            container
            sx={{ mb: 4, mx: 4 }}
            justify="space-between"
            alignContent="flex-end"
          >
            <Grid item xs={2}>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={handleBack}
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={disabled}
                variant="contained"
                size="medium"
                color="primary"
                disableElevation
                fullWidth
                onClick={handleSubmit}
                sx={{
                  mt: 2,
                  width: "100%",
                }}
              >
                {isLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Run Script"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
ConnectAwsPage.displayName = "Connect Aws";
export default ConnectAwsPage;
