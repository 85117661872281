import { authPaths } from "components/router/routes";
import { FlowFFragment, UserFFragment } from "generated/graphql";

const handleSetupStage = (
  currentPathName: string,
  currentFlow: FlowFFragment
) => {
  switch (currentFlow.page) {
    case 1:
      return currentPathName !== `${authPaths.CONNECT}/${currentFlow.setupType}`
        ? `${authPaths.CONNECT}/${currentFlow.setupType}`
        : null;
    case 2: {
      return currentPathName !==
        `${authPaths.VERIFY_SETUP}/${currentFlow.setupType}`
        ? `${authPaths.VERIFY_SETUP}/${currentFlow.setupType}`
        : null;
    }
    case 3:
      return currentPathName !== `${authPaths.CHOOSE_REGIONS}`
        ? authPaths.CHOOSE_REGIONS
        : null;
    default:
      return null;
  }
};
const handleProcessingStage = (
  currentPathName: string,
  currentFlow: FlowFFragment
) => {
  if (currentFlow.stage !== "processing") return null;
  switch (currentFlow.page) {
    default:
      return !currentPathName.startsWith(authPaths.AWS_RESULTS_PROCESSING)
        ? authPaths.AWS_RESULTS_PROCESSING
        : null;
  }
};
const handleResultsStage = (
  currentPathName: string,
  currentFlow: FlowFFragment
) => {
  if (currentFlow.stage !== "results") return null;
  switch (currentFlow.page) {
    default:
      return !currentPathName.startsWith(authPaths.DIAGNOSTIC_DASHBOARD)
        ? authPaths.DIAGNOSTIC_DASHBOARD
        : null;
  }
};
export const getNextOnboardingPath = (
  currentPathName: string,
  user: UserFFragment | null,
  flows: FlowFFragment[] | null | undefined,
  currentFlow: FlowFFragment | null | undefined,
  next: string | null
) => {
  // anonymous user have access to sign in, sign up and verify pages
  if (!user) {
    if (
      currentPathName === authPaths.SIGNIN ||
      currentPathName === authPaths.SIGNUP ||
      currentPathName === authPaths.VERIFY_CODE
    ) {
      return null;
    }
    return `${authPaths.SIGNIN}?next=${encodeURIComponent(currentPathName)}`;
  }
  if (next) {
    const decodedNext = decodeURIComponent(next);
    return decodedNext;
  }

  if (!flows || flows.length === 0 || !currentFlow) {
    if (currentPathName !== authPaths.CONNECT) {
      return authPaths.CONNECT;
    }
    return null;
  }
  if (currentFlow) {
    switch (currentFlow.stage) {
      case "setup":
        return handleSetupStage(currentPathName, currentFlow);
      case "processing":
        return handleProcessingStage(currentPathName, currentFlow);
      case "results":
        return handleResultsStage(currentPathName, currentFlow);
      default:
        return null;
    }
  }
  return null;
};
