/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { Box, Button, Grid, Link, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  sql?: string;
  onSubmit: () => void;
}
const ConnectAthenaSQL: React.FC<Props> = ({ sql, onSubmit }) => {
  return (
    <>
      <Grid item xs={12} sm={8} md={6} sx={{ my: 5, px: 4 }}>
        <Typography
          variant="h5"
          sx={{
            mt: 2,
          }}
        >
          Copy-paste this query to your AWS payer account athena console and run
          this query
        </Typography>
        <Box
          sx={{
            backgroundColor: "#E9E9EA",
            height: "300px",
            maxWidth: "600px",
            my: 2,
          }}
        >
          <Typography
            sx={{
              px: 3,
              py: 3,
              overflow: "scroll",
              maxWidth: [300, "100%"],
              display: "block",
              height: "100%",
            }}
            component="p"
          >
            {sql}
          </Typography>
        </Box>
        <Link component="button" variant="body1" sx={{ ml: 2 }}>
          Need help running the query in your Athena Console?
        </Link>
        <Grid container justify="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={6}>
            <Button
              onClick={onSubmit}
              variant="contained"
              size="medium"
              color="primary"
              disableElevation
              fullWidth
              sx={{
                mt: 2,
                width: "100%",
              }}
            >
              Share your Results
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
ConnectAthenaSQL.displayName = "ConnectAthena SQL";
export default ConnectAthenaSQL;
