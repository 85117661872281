/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { Grid, TextField, Typography } from "@material-ui/core";
import FileInput from "components/general/FileInput";
import { authPaths } from "components/router/routes";
import useRouter from "hooks/use-router";
import React from "react";

interface Props {
  onFileSelected: (s: string) => void;
  isLoading: boolean;
}
const ShareAthenaResults: React.FC<Props> = ({ onFileSelected }) => {
  const { push } = useRouter();
  const [fileName, setFileName] = React.useState<string | null>(null);
  const handleFileSelection = async (file: File) => {
    setFileName(file.name);
    onFileSelected(await file?.text());
  };
  return (
    <Grid container item xs={12}>
      <Typography variant="h5">Upload your csv results file below</Typography>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={8}>
          <TextField
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
            label={fileName}
            sx={{ color: "#90969", mb: 0 }}
          />
        </Grid>
        <Grid item xs={4} sx={{ mt: 3, px: 3 }}>
          <FileInput onFileSelect={handleFileSelection} />
        </Grid>
      </Grid>
    </Grid>
  );
};
ShareAthenaResults.displayName = "Share Results";
export default React.memo(ShareAthenaResults);
