import { graphql } from "msw";

const athenaHandlers = [
  graphql.query("AthenaSQL", (req, res, ctx) => {
    return res(
      ctx.delay(2000),
      ctx.status(200),
      ctx.data({
        sql: "some query",
      })
    );
  }),
];
export default athenaHandlers;
