import { ResponseError } from "api/errors";
import {
  CompanyFlow,
  FlowFFragment,
  FlowStage,
  GetProfileQuery,
  SigninMutation,
  SigninMutationVariables,
  SignoutMutation,
  SignoutMutationVariables,
  SignupMutation,
  SignupMutationVariables,
  useGetProfileQuery,
  UserFFragment,
  useSigninMutation,
  useSignoutMutation,
  useSignupMutation,
} from "generated/graphql";
import useToken from "hooks/use-token";
import React, { createContext, useContext, useState } from "react";
import { UseMutationResult, UseQueryResult } from "react-query";
import { UpdateState } from "use-local-storage-state/src/useLocalStorageStateBase";
import useCloudFlow from "./use-cloud-flow";

type Mutation<Response, Input> = UseMutationResult<
  Response,
  ResponseError,
  Input,
  unknown
> | null;

export interface AuthContext {
  token: string | null;
  user: UserFFragment | null;
  flows: FlowFFragment[] | null | undefined;
  flow: FlowFFragment | null | undefined;
  isAdmin: boolean;
  signin: Mutation<SigninMutation, SigninMutationVariables>;
  signout: Mutation<SignoutMutation, SignoutMutationVariables>;
  signup: Mutation<SignupMutation, SignupMutationVariables>;
  setCurrentFlow: UpdateState<string | null> | null;
  profileQuery: UseQueryResult<GetProfileQuery, ResponseError> | null;
  isLoading: boolean;
}
const authContext = createContext<AuthContext>({
  token: null,
  user: null,
  flows: null,
  flow: null,
  isAdmin: false,
  signin: null,
  signout: null,
  signup: null,
  setCurrentFlow: null,
  profileQuery: null,
  isLoading: true,
});

const useAuthProvider = () => {
  const [token, setToken] = useToken();
  const [currentFlowId, setCurrentFlow] = useCloudFlow();
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const profileQuery = useGetProfileQuery(
    {},
    {
      enabled: isAuthenticated && !!token,
      retry: (failureCount, error) => {
        if (error?.graphqlErrors?.[0]?.message?.startsWith("Unauthorized")) {
          setToken(null);
          setIsAuthenticated(false);
          window.location.reload();
          return false;
        }
        return failureCount < 3;
      },
    }
  );

  const user = profileQuery.data || null;
  const weightFlow = (flow: CompanyFlow) => {
    let stageWeight = 0;
    if (flow.stage === FlowStage.Setup) stageWeight = 10;
    if (flow.stage === FlowStage.Processing) stageWeight = 20;
    if (flow.stage === FlowStage.Results) stageWeight = 30;
    return stageWeight + (flow.page || 0);
  };
  const getCurrentFlow = () => {
    if (currentFlowId)
      return user?.flows?.find((flow) => flow?.id === currentFlowId);
    if (user?.flows && user.flows?.length < 1) return null;
    const heaviestFlow = user?.flows?.reduce((prev, current) => {
      return weightFlow(prev as CompanyFlow) >
        weightFlow(current as CompanyFlow)
        ? prev
        : current;
    });
    return heaviestFlow;
  };
  const signin = useSigninMutation({
    onSuccess: (data) => {
      const newToken = data?.Signin?.token;
      if (!newToken) {
        throw new Error(`Signin mutation didn't respond with a token`);
      }
      setToken(newToken);
      setTimeout(() => {
        // fix racing condition, when the token was set, the app tries directly
        // to fetch the profile data directly, and fetch does not have enough
        // time to get the content
        setIsAuthenticated(true);
      }, 500);
      window.location.reload();
    },
  });
  const signup = useSignupMutation();

  const signout = useSignoutMutation({
    onSuccess: () => {
      setToken(null);
      setIsAuthenticated(false);
      window.location.reload();
    },
  });
  return {
    token,
    user: user?.me || null,
    flows: (user?.flows as FlowFFragment[]) || null,
    flow: getCurrentFlow(),
    isAdmin: !!user,
    signin,
    signout,
    signup,
    setCurrentFlow,
    profileQuery,

    isLoading: !!token && !user,
  };
};

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}> {children}</authContext.Provider>;
};

export const useAuth = (): AuthContext => {
  return useContext(authContext);
};
