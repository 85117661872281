/** @jsxImportSource theme-ui */
import { Box } from "@material-ui/core";
import OnboardingBar from "components/navigation/OnboardingBar";
import VerifyAthenaSetup from "components/verify-setup/VerifyAthenaSetup";

import * as React from "react";

const VerifySetupAthenaPage: React.FC = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundImage: `linear-gradient(288.06deg, #F4A36D 1.57%, rgba(180, 128, 185, 0.7) 99.24%), linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(assets/authbg.png);`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <OnboardingBar
        sx={{
          mt: 88,
          px: [30, 0],
        }}
      />
      <VerifyAthenaSetup />
    </Box>
  );
};
VerifySetupAthenaPage.displayName = "VerifySetupAthenaPage";
export default VerifySetupAthenaPage;
